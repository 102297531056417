import { colorUsage, fontFamily, fontSize, fontWeight, getSpacing } from 'stylesheet';

import { Text } from 'styling/Typography.style';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${getSpacing(20)};
  min-height: ${getSpacing(20)};
  padding: 0 ${getSpacing(6)};
  background-color: ${colorUsage.footerBackground};
`;

export const FooterContents = styled.div`
  width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

FooterContainer.displayName = 'FooterContainer';

export const FooterLink = styled.a`
  font-weight: ${fontWeight.light};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  color: ${colorUsage.footerLinkTextColor};
  text-decoration: none;
  cursor: pointer;
`;

export const FooterText = styled(Text)`
  font-weight: ${fontWeight.light};
  font-size: ${fontSize.medium};
  color: ${colorUsage.footerLinkTextColor};
  text-decoration: none;
  padding-bottom: ${getSpacing(3)};
`;
