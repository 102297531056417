import { Footer } from './Footer';
import React from 'react';
import { render, screen } from '@testing-library/react';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

describe('Footer components', () => {
  it('should have a footer with copyright and privacy statement', () => {
    render(<Footer />);
    const currentYear = new Date().getFullYear();
    expect(screen.getByText(`Copyright © ${currentYear} International Timber`)).toBeTruthy();
    expect(screen.getByText('Privacy Statement')).toBeTruthy();
    expect(screen.getByText('Privacy Statement').closest('a')?.href).toBe(
      'https://www.internationaltimber.com/privacy-statement/',
    );
  });
});
