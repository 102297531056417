import { Header } from './Header';
import React from 'react';
import { render } from '@testing-library/react';

jest.mock('react-router-dom', () => ({
  Link: () => <div />,
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest
    .fn()
    .mockImplementationOnce((fn) => fn('BackToLink'))
    .mockImplementationOnce((fn) => fn('BackToText')),
}));

jest.mock('redux/CMS/selectors', () => ({
  getCMSFieldById: () => (id: string) => {
    return id === 'BackToText' ? 'BACK TO Int Timber' : 'https://www.internationaltimber.com/';
  },
}));

describe('Header components', () => {
  it('should have a header that renders correctly', () => {
    const { container } = render(<Header />);
    expect(container).toMatchSnapshot();
  });
});
