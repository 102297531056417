import { colorUsage, fontFamily, fontSize, fontWeight, getSpacing } from 'stylesheet';

import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { DeviceSize } from 'pages/Models/useResponsive';

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${getSpacing(20)};
  min-height: ${getSpacing(20)};
  padding: 0 ${getSpacing(6)};
  font-size: ${fontSize.large};
  background-color: ${colorUsage.headerBackground};
`;

export const HeaderContents = styled.div`
  width: 1140px;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

HeaderContainer.displayName = 'HeaderContainer';

export const Logo = styled.img<{ deviceSize: DeviceSize }>`
  margin-top: ${getSpacing(5)};
  margin-bottom: ${getSpacing(5)};
  max-width: ${(props) =>
    props.deviceSize === DeviceSize.Phone ? getSpacing(20) : getSpacing(35)};
`;

Logo.displayName = 'Logo';

export const HeaderLink = styled.a`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  color: ${colorUsage.headerLinkTextColor};
  text-decoration: none;
  padding: 0 ${getSpacing(3)};
  cursor: pointer;
`;

export const InternalHeaderLink = styled(RouterLink)`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  color: ${colorUsage.headerLinkTextColor};
  text-decoration: none;
  padding: 0 ${getSpacing(3)};
  cursor: pointer;
`;

export const LinkContainer = styled.div`
  display: flex;
`;
