export const mockCMSContent: Record<string, string> = {
  pricesFromText: 'Prices from*:',
  selectFeaturePlaceholder: 'Select Feature',
  featureConfiguratorTitle: 'Features',
  CopyrightNameText: 'International Timber',
  PrivacyStatementLink: 'https://www.internationaltimber.com/privacy-statement/',
  WhatIsIncludedLabel: 'What is included?',
  selectFeatureText: 'Please select a feature to see the finishes',
  noFinishesForFeatureText: 'Currently there are no finishes for this feature',
  noFinishesForModelText: 'Currently there are no finishes on this model',
  finishesConfiguratorTitle: 'Finishes',
  SubHeaderHomeText: 'Select a house style',
  HeaderText: 'Design Your Home',
  HeaderHelpText: 'Help',
  WhatIsIncludedText: 'what is included text',
};
