/* eslint-disable max-lines */
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import { RequestAQuoteForm } from 'pages/Models/RequestAQuoteForm';
import { useSendCustomerQuoteEmail } from 'redux/CustomerModelConfig/hooks';

const commonProps = {
  modelId: '123',
  getCustomerLink: () => 'mockLink',
};
jest.mock('redux/useSelector', () => ({
  useSelector: jest
    .fn()
    .mockImplementationOnce((fn) => fn('ContactOptInText'))
    .mockImplementationOnce((fn) => fn('ContactDetailsRequestText')),
}));

jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: () => ({
    modelId: '123',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  }),
}));
jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    estimatedCost: {
      threeStarClosed: 60000,
    },
  }),
}));
jest.mock('redux/CMS', () => ({
  getCMSFieldById: () => (id: string) => {
    return id === 'ContactOptInText'
      ? 'Opt in?'
      : 'Thank you for your interest in Int Timber. Please enter your contact details below. The Int Timber team will contact you to discuss your project';
  },
  getConstructionCMSFields: () => ({
    oneStarOpen: '3* - Closed',
    twoStarOpen: '3* - Closed',
    twoStarClosed: '3* - Closed',
    threeStarOpen: '3* - Closed',
    threeStarClosed: '3* - Closed',
    fourStarClosed: '3* - Closed',
    fiveStarClosed: '3* - Closed',
  }),
}));
const mockSendCustomerQuoteEmail = jest.fn();
jest.mock('redux/CustomerModelConfig/hooks', () => ({
  useSendCustomerQuoteEmail: jest.fn(() => [{}, mockSendCustomerQuoteEmail]),
}));

describe('RequestAQuoteForm', () => {
  it('should have a title, subtitle and button', () => {
    render(<RequestAQuoteForm {...commonProps} />);
    expect(screen.queryAllByText('Request a Quote').length).toBe(2);
    expect(
      screen.queryByText(
        'Thank you for your interest in Int Timber. Please enter your contact details below. The Int Timber team will contact you to discuss your project',
      ),
    ).toBeTruthy();
  });
  it('should have 3 fields and checkbox', () => {
    render(<RequestAQuoteForm {...commonProps} />);
    expect(screen.queryByLabelText('First Name*')).toBeTruthy();
    expect(screen.queryByLabelText('Last Name*')).toBeTruthy();
    expect(screen.queryByLabelText('Email*')).toBeTruthy();
    expect(screen.queryByLabelText('Contact Number*')).toBeTruthy();
    expect(screen.queryByTestId('optInContact')).toBeTruthy();
  });
  it('should have no errors if all fields are valid', async () => {
    render(<RequestAQuoteForm {...commonProps} />);
    fireEvent.change(screen.getByLabelText('First Name*'), { target: { value: 'Rob' } });
    fireEvent.change(screen.getByLabelText('Last Name*'), { target: { value: 'Cronin' } });
    fireEvent.change(screen.getByLabelText('Email*'), { target: { value: 'rob@email.com' } });
    fireEvent.change(screen.getByLabelText('Contact Number*'), { target: { value: '123' } });
    fireEvent.click(screen.getByTestId('optInContact'));
    await waitFor(() => {
      expect(screen.queryByText('Please enter your first name')).toBeFalsy();
      expect(screen.queryByText('Please enter your last name')).toBeFalsy();
      expect(screen.queryByText('Please enter a valid email')).toBeFalsy();
      expect(screen.queryByText('Please enter your contact number')).toBeFalsy();
      expect(screen.queryByText('You must opt in to receive a quotation')).toBeFalsy();
    });
  });
  it('should have 3 required fields show errors', async () => {
    render(<RequestAQuoteForm {...commonProps} />);
    fireEvent.blur(screen.getByLabelText('First Name*'));
    fireEvent.blur(screen.getByLabelText('Last Name*'));
    fireEvent.blur(screen.getByLabelText('Email*'));
    fireEvent.blur(screen.getByLabelText('Contact Number*'));
    fireEvent.blur(screen.getByTestId('optInContact'));
    await waitFor(() => {
      expect(screen.queryByText('Please enter your first name')).toBeTruthy();
      expect(screen.queryByText('Please enter your last name')).toBeTruthy();
      expect(screen.queryByText('Please enter a valid email')).toBeTruthy();
      expect(screen.queryByText('Please enter your contact number')).toBeTruthy();
      expect(screen.queryByText('You must opt in to receive a quotation')).toBeTruthy();
    });
  });
  it('should have an email field that requires a valid email', async () => {
    render(<RequestAQuoteForm {...commonProps} />);
    const emailInput = screen.getByLabelText('Email*');
    fireEvent.blur(emailInput);
    fireEvent.change(emailInput, { target: { value: 'rob' } });
    await waitFor(() => {
      expect(screen.queryByText('Please enter a valid email')).toBeTruthy();
    });
    fireEvent.change(emailInput, { target: { value: 'rob@email.com' } });
    await waitFor(() => {
      expect(screen.queryByText('Please enter a vald email')).toBeFalsy();
    });
  });
  it('should have a disabled button until the fields are valid', async () => {
    render(<RequestAQuoteForm {...commonProps} />);
    expect(screen.getAllByText('Request a Quote')[1].getAttribute('disabled')).not.toBe(null);
    fireEvent.change(screen.getByLabelText('First Name*'), { target: { value: 'Rob' } });
    fireEvent.change(screen.getByLabelText('Last Name*'), { target: { value: 'Cronin' } });
    fireEvent.change(screen.getByLabelText('Email*'), { target: { value: 'rob@email.com' } });
    fireEvent.change(screen.getByLabelText('Contact Number*'), { target: { value: '123' } });
    fireEvent.click(screen.getByTestId('optInContact'));
    await waitFor(() => {
      expect(screen.getAllByText('Request a Quote')[1].getAttribute('disabled')).toBe(null);
    });
  });
  // TODO: get location dropdown so test can pass
  // it('should call the submit function when submitted', async () => {
  //   render(<RequestAQuoteForm {...commonProps} />);
  //   fireEvent.change(screen.getByLabelText('Full Name'), { target: { value: 'Rob Cronin' } });
  //   fireEvent.change(screen.getByLabelText('Email'), { target: { value: 'rob@email.com' } });
  //   fireEvent.change(screen.getByLabelText('Contact Number'), { target: { value: '123' } });
  //   fireEvent.click(screen.getByTestId('optInContact'));
  //   fireEvent.click(screen.getAllByText('Request a Quote')[1]);
  //   await waitFor(() => {
  //     expect(mockSendCustomerQuoteEmail).toHaveBeenCalledWith({
  //       customerModelConfig: {
  //         features: {},
  //         materials: {},
  //         modelId: '123',
  //         construction: 'threeStarClosed',
  //       },
  //       email: 'rob@email.com',
  //       modelId: '123',
  //       name: 'Rob Cronin',
  //       phoneNumber: '123',
  //       constructionLabel: '3* - Closed',
  //       constructionPrice: '£60,000',
  //       modelLink: 'mockLink',
  //     });
  //   });
  // });
  it('return a success message when successful, hide form fields', async () => {
    // @ts-expect-error mockImplementation
    useSendCustomerQuoteEmail.mockImplementationOnce(() => [
      { value: { success: 'Success: Email sent' } },
      mockSendCustomerQuoteEmail,
    ]);
    render(<RequestAQuoteForm {...commonProps} />);
    expect(screen.queryByText('Success: Email sent')).toBeTruthy();

    expect(screen.queryByText('First Name*')).toBeNull();
    expect(screen.queryByText('Last Name*')).toBeNull();
    expect(screen.queryByText('Email*')).toBeNull();
    expect(screen.queryByText('Contact Number*')).toBeNull();
    expect(screen.queryByText('Project Location*')).toBeNull();
    expect(screen.queryByText('Type of Enquiry')).toBeNull();
    expect(screen.queryByText('What is your budget?')).toBeNull();
  });
  it('return an error message when error, hide form fields', async () => {
    // @ts-expect-error mockImplementation
    useSendCustomerQuoteEmail.mockImplementationOnce(() => [
      { value: { error: 'Error: Email failed' } },
      mockSendCustomerQuoteEmail,
    ]);
    render(<RequestAQuoteForm {...commonProps} />);
    expect(screen.queryByText('Error: Email failed')).toBeTruthy();

    expect(screen.queryByText('First Name*')).toBeNull();
    expect(screen.queryByText('Last Name*')).toBeNull();
    expect(screen.queryByText('Email*')).toBeNull();
    expect(screen.queryByText('Contact Number*')).toBeNull();
    expect(screen.queryByText('Project Location*')).toBeNull();
    expect(screen.queryByText('Type of Enquiry')).toBeNull();
    expect(screen.queryByText('What is your budget?')).toBeNull();
  });
});
