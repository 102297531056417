import { fireEvent, render, screen } from '@testing-library/react';

import { IMaterialConfig } from 'redux/Models/types';
import React from 'react';
import { TextureConfigurator } from 'pages/Models/TextureConfigurator';
import { ConfiguratorContextProvider } from 'context/ConfiguratorContext';

interface ICommonProps {
  material: IMaterialConfig;
  sketchfabApiRef: { current: null };
}

const commonProps: ICommonProps = {
  material: {
    value: 'Aluminium_Doors',
    label: 'Aluminium Doors',
    finishHeading: 'Materials',
    availableTextures: [
      {
        textureId: '0bbb74fa-32e2-4aaf-9ef3-dc17386391c3',
        default: true,
      },
      {
        textureId: 'e6292656-7931-4e6f-87ba-3a93e7ae3945',
      },
      {
        textureId: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      },
    ],
  },
  sketchfabApiRef: { current: null },
};
jest.mock('services/sketchfab/texture');

jest.mock('redux/CustomerModelConfig');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));

jest.mock('redux/useSelector', () => ({
  useSelector: () => [
    {
      id: '0bbb74fa-32e2-4aaf-9ef3-dc17386391c3',
      label: 'Oak',
      url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood.jpeg',
    },
    {
      id: 'e6292656-7931-4e6f-87ba-3a93e7ae3945',
      label: 'Beech',
      url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood2.jpeg',
    },
    {
      id: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      label: 'Painted',
      canChangeColor: true,
    },
  ],
}));

describe('TextureConfigurator Component', () => {
  it('should have a title', () => {
    render(<TextureConfigurator {...commonProps} />);
    expect(screen.queryByText('Materials')).toBeTruthy();
  });
  it('should not have a title if finishHeading is not provided', () => {
    render(
      <TextureConfigurator
        {...commonProps}
        material={{ ...commonProps.material, finishHeading: undefined }}
      />,
    );
    expect(screen.queryByText('Materials')).toBeFalsy();
  });
  it('should not have a title and square if the material does not allow it', () => {
    render(
      <TextureConfigurator
        {...commonProps}
        material={{ ...commonProps.material, availableTextures: undefined }}
      />,
    );
    expect(screen.queryByText('Materials')).toBeFalsy();
  });
  it('should have a title and 3 squares if the material is aluminium doors', () => {
    render(<TextureConfigurator {...commonProps} />);
    expect(screen.queryByText('Materials')).toBeTruthy();
    expect(screen.queryByAltText('Oak')).toBeTruthy();
    expect(screen.queryByAltText('Beech')).toBeTruthy();
  });
  it('should change the beech to blue border when selected', () => {
    render(
      <ConfiguratorContextProvider>
        <TextureConfigurator {...commonProps} />
      </ConfiguratorContextProvider>,
    );
    fireEvent.click(screen.getByAltText('Beech'));
    const oakStyle = window.getComputedStyle(screen.getByAltText('Oak'));
    expect(oakStyle.border).toBe('3px solid transparent');
    const beechStyle = window.getComputedStyle(screen.getByAltText('Beech'));
    expect(beechStyle.border).toBe('3px solid #bf0d3e');
  });
});
